<template>
  <div class="main-content">
    <base-bread-crumbs/>
    <div class="main-content__inner">
      <div class="page-title">{{ $t('shipments') }}</div>

      <div class="list-page-header">
        <OrdersTabs
          v-if="statuses"
          :data="statuses"
          class="tabs"
          @changeActiveTab="changeActiveTab"
        />

        <base-input
          :placeholder="$t('searchProductPlaceholder')"
          containerClass="goods-product-panel--search"
          :fullWidth="true"
          iconName="search-icon"
        />
      </div>

      <template v-if="totalPages > 0">
        <!--div class="order-message">
          {{ pageLabel }}
        </div-->

        <OrdersTable
          class="orders-table"
          :activeStatus="activeStatus"
          :orders="ordersTableData"
          @sortByDateAsc="sortOrdersByAsc"
          @sortByDateDesc="sortOrdersByDesc"
          @accept="acceptOrder"
          @decline="showMDeclineModal"
          @getShippingLabel="getShippingLabel"
        />

        <LeftRight>
          <template #content>
            <ListPagination
              :pagination="pagination"
              @onSelectPage="pageChanged"/>

            <LeftRight>
              <template #content>
                <p class="page-size-text">
                  {{$t('ordersOnPage')}}
                  :</p>
                <base-select
                  :value="perPage"
                  :options="[10, 20, 50, 100]"
                  :clearable="false"
                  @input="tableSizeChanged"
                />
              </template>
            </LeftRight>
          </template>
        </LeftRight>
      </template>
    </div>

    <div
      v-if="totalPages === 0"
      class="message-panel"
    >
      <div class="message-panel--content">
        {{ $t('noItems') }}
      </div>
    </div>

    <BaseModal
      v-if="showDeclineOrderModal"
      @close="cancel('showDeclineOrderModal')">
      <template #header>{{`Order ${selectedOrder.shipmentNumber}`}}</template>
      <template #body>
        {{$t('rejectOrderModal')}}
      </template>
      <template #buttons>
        <WarnButton value="Reject" @click="declineOrder"></WarnButton>
        <simple-button value="Cancel" @click="cancel('showDeclineOrderModal')"></simple-button>
      </template>
    </BaseModal>

    <router-view></router-view>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs.vue'
import OrdersTabs from './OrdersTabs'
import ordersStatuses, {
  ORDER_STATUS_ALL_SHIPMENTS,
  ORDER_STATUS_PENDING_PROCESSING,
  ORDER_STATUS_ACCEPTED_BY_SELLER,
  ORDER_STATUS_CANCELLED_BY_SELLER,
  ORDER_STATUS_SENT_TO_SM,
  ORDER_STATUS_DELIVERED_TO_SM,
  ORDER_STATUS_DELIVERING_TO_CLIENT,
  ORDER_STATUS_DELIVERED_TO_CLIENT
} from '../../../helpers/consts/order-statuses'
import OrdersTable from './OrdersTable'
import BaseModal from '../../ui/modals/BaseModal'
import WarnButton from '../../ui/buttons/WarnButton'
import ListPagination from '../../ui/tables/pagination/ListPagination'
import LeftRight from '../../ui/layout/LeftRight'
import { mapState } from 'vuex'
import helpers from '../../../helpers/helpers'
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import BaseInput from "smarket-ui/src/components/forms/BaseInput.vue";

export default {
  name: 'Orders',
  components: {
    BaseInput,
    LeftRight,
    ListPagination,
    WarnButton,
    BaseModal,
    OrdersTable,
    OrdersTabs,
    BaseBreadCrumbs,
    BaseSelect,
    SimpleButton,
  },
  data () {
    return {
      activeStatus: 1,
      showDeclineOrderModal: false,
      selectedOrder: null,
    }
  },

  computed: {
    ...mapState(
      {
        orders: state => state.orders.orders,
        pagination: state => state.orders.pagination,
        ordersStatuses: state => state.orders.ordersStatuses,
        currentPage: state => state.orders.pagination ? state.orders.pagination.page : null,
        totalPages: state => state.orders.pagination ? state.orders.pagination.total : null,
        perPage: state => state.orders.pagination ? state.orders.pagination.per_page : null,
      }
    ),

    statuses () {
      let allShipmentsCount = 0
      const statuses = JSON.parse(JSON.stringify(this.ordersStatuses)).map(status => {
        status.isActive = status.status === this.activeStatus
        status.statusName = this.$t(status.status_name)

        allShipmentsCount += status.count

        return status
      })

      statuses.unshift({
        status: ORDER_STATUS_ALL_SHIPMENTS,
        status_name: this.$t('allShipments'),
        isActive: this.activeStatus === ORDER_STATUS_ALL_SHIPMENTS,
        count: allShipmentsCount,

      })

      return statuses
    },

    ordersTableData () {
      return Array.isArray(this.orders) ? this.orders.map(order => {
        const createdAt = new Date(order.createdAt)
        const createdDate = helpers.getDate(createdAt)
        const createdTime = helpers.getTime(createdAt)
        const shipmentNumber = order.orderId
        const status = ordersStatuses[order.status]
        const firstItem = order.orderItems ? order.orderItems[0] : {}
        const details = `${this.$t('modelSKU')}: ${firstItem.sku}, ${firstItem.quantity} ${this.$t('quantityShort')}`
        const firstItemTitle = firstItem.name
        const totalPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(order.totalSum)
        const { photo, name, brand, category, sku } = firstItem


        return {
          id: order.id,
          createdDate,
          createdTime,
          shipmentNumber,
          status,
          statusId: order.status,
          details,
          firstItemTitle,
          totalPrice,
          photo,
          name,
          brand: brand?.name,
          category: category?.name,
          sku,
        }
      }) : null
    },

    pageLabel () {
      switch (this.activeStatus) {
        case ORDER_STATUS_ALL_SHIPMENTS:
          return this.$t('ordersAllShipmentsText')
        case ORDER_STATUS_PENDING_PROCESSING:
          return this.$t('ordersWaitingForApprovalText')
        case ORDER_STATUS_ACCEPTED_BY_SELLER:
          return this.$t('ordersApprovedText')
        case ORDER_STATUS_CANCELLED_BY_SELLER:
          return this.$t('ordersCancelledText')
        case ORDER_STATUS_SENT_TO_SM:
          return this.$t('ordersSentToSMText')
        case ORDER_STATUS_DELIVERED_TO_SM:
          return this.$t('ordersDeliveredToSMText')
        case ORDER_STATUS_DELIVERING_TO_CLIENT:
          return this.$t('ordersInTransitText')
        case ORDER_STATUS_DELIVERED_TO_CLIENT:
          return this.$t('ordersDeliveredText')
        default:
          return ''
      }
    },
  },

  methods: {
    changeActiveTab (status) {
      this.$router.push({
        name: 'orders',
        params: {
          status,
        },
      })
    },

    sortOrdersByAsc () {
      //
    },

    sortOrdersByDesc () {
      //
    },

    showMDeclineModal (order) {
      this.showDeclineOrderModal = true
      this.selectedOrder = order
    },

    cancel (key) {
      this[key] = false
      this.selectedOrder = null
    },

    async getOrders ({ status, page, perPage }) {
      try {
        await this.$store.dispatch('getOrders', {
          status,
          page,
          perPage,
        })
        await this.$store.dispatch('getStatusesWithCount')
      } catch (error) {
        await this.$store.dispatch('showError', error)
      }
    },

    async changeOrderStatus (orderId, status, description) {
      try {
        const order = this.orders.filter(order => order.id === orderId)[0]

        await this.$store.dispatch('changeOrderStatus', {
          orderId,
          status,
        })
        await this.getOrders({
          status: this.activeStatus,
          page: this.currentPage,
          perPage: this.perPage,
        })

        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: `${this.$t('order')}: ${order?.orderId}`,
          description,
        })
      } catch (error) {
        await this.$store.dispatch('showError', error)
      }
    },

    acceptOrder (order) {
      this.changeOrderStatus(
        order.id,
        ORDER_STATUS_ACCEPTED_BY_SELLER,
        this.$t('orderAccepted'),
      )
    },

    async declineOrder () {
      await this.changeOrderStatus(
        this.selectedOrder.id,
        ORDER_STATUS_CANCELLED_BY_SELLER,
        this.$t('orderRejected')
      )
      this.showDeclineOrderModal = false
    },

    pageChanged (newPage) {
      this.$store.dispatch('ordersPageChanged', {
        newPage,
        status: this.activeStatus,
      })
    },

    tableSizeChanged (perPage) {
      this.$store.dispatch('ordersPageSizeChanged', {
        perPage,
        status: this.activeStatus,
      })
    },

    getShippingLabel ({ orderId, shipmentNumber }) {
      this.$store.dispatch('loadShippingLabel', {
        orderId,
        shipmentNumber,
      })
    },
  },

  async beforeRouteUpdate (to, from, next) {
    const initialStatus = Object.keys(ordersStatuses)[0]
    const selectedStatus = to.params.status === undefined ? initialStatus : to.params.status

    this.activeStatus = Number(selectedStatus)

    await this.getOrders({
      status: this.activeStatus,
      page: this.currentPage || 1,
      perPage: this.perPage || 20,
    })

    next()
  },

  created () {
    this.activeStatus = Number(this.$route.params.status)
  },

  async mounted () {
    this.$store.commit('setLoadingStatus')
    await this.getOrders({
      status: this.activeStatus,
      page: 1,
      perPage: 20,
    })
    this.$store.commit('setSuccessStatus')
  },

  beforeRouteLeave (to, from, next) {
    this.activeStatus = null
    next()
  },
}
</script>

<style lang="scss" scoped>
.orders-table {
  margin-bottom: 40px;
}
.page-size-text {
  margin-right: 30px;
  color: $text-gray;
  font-size: 14px;
}

.order-message {
  background: #F4F0FF;
  border-radius: 5px;
  color: $text-gray;
  font-size: 14px;
  line-height: 20px;
  padding: 20px;
  margin: 20px 0;
}

.list-page-header {
  background-color: #F3F5F7;
  border-radius: 14px;
  padding: 12px;
  margin-bottom: 20px;
}

.product-item {
  border: solid #D4DADF;
  border-width: 0 1px 1px 1px;
}
.tabs {
  margin-bottom: 20px;
}
</style>
