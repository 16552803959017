<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              {{title}}
            </slot>

            <svg-icon
              class-name="modal-close"
              icon-name="close-light"
              @click="$emit('close')"/>
          </div>

          <div class="modal-body">
            <slot name="body">
              {{body}}
            </slot>
          </div>

          <div class="modal-footer">
            <div class="button-wrap">
              <slot name="buttons">
                <BaseButton @click="$emit('close')">{{ $t('close') }}</BaseButton>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import BaseButton from '../buttons/BaseButton'
export default {
  name: 'BaseModal',
  components: {
    BaseButton,
  },
  props: [
    'title',
    'body',
  ],
  mounted () {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.paddingLeft = '15px'
  },

  destroyed () {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.paddingLeft = ''
  },
}
</script>

<style lang="scss" scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    font-size: 14px;
    width: 340px;
    padding: 25px 20px 30px 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-header {
    font-size: 16px;
    font-weight: bold;
    padding: 0 20px 30px 0;
    border-bottom: 1px solid $color-line;
    position: relative;
  }

  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .modal-body {
    margin: 30px 0;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
