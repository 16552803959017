<template>
  <BaseTabs :data="data" @onClick="changeActiveTab"/>
</template>

<script>
import BaseTabs from '../../ui/layout/BaseTabs'
export default {
  name: 'OrdersTabs',
  components: { BaseTabs },
  props: [
    'data',
  ],
  methods: {
    changeActiveTab (tab) {
      this.$emit('changeActiveTab', tab.status)
    },
  },
}
</script>

<style scoped>

</style>
