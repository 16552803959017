<template>
  <BaseTable>
    <template #header>
      <th>
        {{$t('date')}}
      </th>
      <th>
        Photo
      </th>
      <th>
        Name
      </th>
      <th>SKU</th>
      <th>
        {{$t('ordersTableStatus')}}
      </th>
      <th>
        {{$t('orderStatus')}}
      </th>
      <th>
        {{$t('orderTotal')}}
      </th>
      <template v-if="showActions">
        <th v-if="showActions">
          {{$t('action')}}
        </th>
      </template>
      <template v-else-if="showShippingLabel">
        <th>
          {{$t('shippingLabel')}}
        </th>
      </template>
    </template>
    <template #body>
      <tr v-for="order in orders" :key="order.id">
        <td>
          <p v-text="order.createdDate"></p>
          <p
            class="small-text"
            v-text="order.createdTime"></p>
        </td>
        <td>
          <ProductImage
            :url="order.photo"
          />
        </td>
        <td class="name-cell">
          {{ order.name }}
          <p class="small-text">
            {{ order.category }}, {{ order.brand }}
          </p>
        </td>
        <td v-text="order.sku"/>
        <td>
          <CopyToClipboard>
            <span class="link" @click="() => { openOrder(order.id) }">{{order.shipmentNumber}}</span>
          </CopyToClipboard>
        </td>
        <td v-text="order.status"/>
        <td v-text="order.totalPrice"/>
        <template v-if="showActions">
          <td class="actions-cell">
            <div class="actions">
              <svg-icon
                icon-name="decline"
                class-name="action action-decline"
                @click="decline(order)"/>
              <svg-icon
                icon-name="accept"
                class-name="action action-accept"
                @click="accept(order)"/>
            </div>
          </td>
        </template>
        <template v-else-if="showShippingLabel">
          <td>
            <LinkWithIcon
              @click="getShippingLabel(order.id, order.shipmentNumber)"
              target="_blank"
              icon-name="pdf"
              :href="`order/${order.id}/label`"
              :disabled="shippingLabelDisabled(order.statusId)"
            >
              {{$t('shippingLabel')}}
            </LinkWithIcon>
          </td>
        </template>
      </tr>
    </template>
  </BaseTable>
</template>

<script>
import BaseTable from '../../ui/tables/BaseTable'
import CopyToClipboard from '../../ui/CopyToClipboard'
import { ORDER_STATUS_CANCELLED_BY_SELLER, ORDER_STATUS_PENDING_PROCESSING } from '../../../helpers/consts/order-statuses'
import LinkWithIcon from '../../ui/ links/LinkWithIcon'
import ProductImage from "@/components/ui/images/ProductImage.vue";
export default {
  name: 'OrdersTable',
  components: {
    ProductImage,
    LinkWithIcon,
    CopyToClipboard,
    BaseTable,
  },
  props: [
    'orders',
    'activeStatus',
  ],
  computed: {
    showActions () {
      return this.activeStatus === ORDER_STATUS_PENDING_PROCESSING
    },

    showShippingLabel () {
      return this.activeStatus !== ORDER_STATUS_CANCELLED_BY_SELLER
    },
  },

  methods: {
    sortByDateAsc () {
      this.$emit('sortByDateAsc')
    },

    sortByDateDesc () {
      this.$emit('sortByDateDesc')
    },

    accept (order) {
      this.$emit('accept', order)
    },

    decline (order) {
      this.$emit('decline', order)
    },

    getShippingLabel (orderId, shipmentNumber) {
      this.$emit('getShippingLabel', {
        orderId,
        shipmentNumber,
      })
    },

    shippingLabelDisabled (orderStatusId) {
      return orderStatusId === ORDER_STATUS_PENDING_PROCESSING || orderStatusId === ORDER_STATUS_CANCELLED_BY_SELLER
    },
    async openOrder (orderId) {
      this.$store.commit('setLoadingStatus')
      await this.$router.push({ name: 'Order', params: { orderId } })
    },
  },
}
</script>

<style lang="scss" scoped>
  td {
    vertical-align: middle;
  }
  .small-text {
    color: $text-gray;
  }

  .action {
    cursor: pointer;
    transition: 0.2s ease fill;

    &-decline {
      cursor: pointer;
      fill: $color-red-light;
      margin-right: 10px;

      &:hover {
        fill: $color-red;
      }
    }

    &-accept {
      cursor: pointer;
      fill: $color-primary;

      &:hover {
        fill: $color-primary-hover;
      }
    }
  }

  .link {
    color: $color-primary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-primary-hover;
    }
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
  }
  .name-cell {
    width: 516px;
  }
</style>
