<template>
  <div class="left-right">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'LeftRight',
}
</script>

<style lang="scss" scoped>
  .left-right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
</style>
