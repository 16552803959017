<template>
  <div @click="copyContent">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CopyToClipboard',
  methods: {
    async copyContent (e) {
      try {
        if (navigator.clipboard) { // Chrome
          await navigator.clipboard.writeText(e.target.textContent)
        } else if (window.clipboardData) { // Internet Explorer
          window.clipboardData.setData('Text', e.target.textContent)
        } else {
          throw new Error()
        }
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: this.$t('ordersTableStatus'),
          description: this.$t('copySuccess'),
        })
      } catch (error) {
      }
    },
  },
}
</script>

<style scoped>

</style>
